<template>
  <div class="d-flex flex-column align-center justify-center" style="height: 100%">
    <v-card width="65%" elevation="8">
      <v-card-text class="pa-6">
        <h1 class="text-center text-h3 mt-0 mb-6">Sampai jumpa!</h1>
        <p class="text-center text-body-1 text--primary mt-6 mb-0">
          Anda sudah berhasil logout dari Situs GKBI. <br />
          Anda mungkin perlu untuk logout juga dari {{ signInProvider }}
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'LogoutView',

  computed: {
    signInProvider () {
      var userToken = this.$store.getters['auth/userToken']
      if (!userToken) return null
      return userToken.signInProvider === 'google.com' ? 'Google' : 'Facebook'
    }
  }
}
</script>
